<template>
  <section class="section-profile sectionProfileReq">
    <h2 class="section-profile__title">
      Карточка компании
    </h2>

    <Form
      v-slot="{ errors }"
      class="section-profile__form"
      @submit="checkForm"
    >
      <fieldset class="section-profile__group">
        <!-- Название организации -->
        <vInput
          id="name_organization"
          v-model="name_organization"
          :value="name_organization"
          placeholder="Полное наименование организации"
          :required="true"
          rules="required"
        />

        <!-- ИНН -->
        <vInput
          id="inn"
          v-model="inn"
          :value="inn"
          placeholder="ИНН"
          type="number"
          :required="true"
          rules="min:10|max:12"
          mask="############"
        />

        <!-- Телефон -->
        <vInput
          id="phone"
          v-model="phone"
          :value="phone"
          placeholder="Телефон"
          :required="true"
          rules="phone"
        />

        <vButton
          type="submit"
          :fix-width="true"
          :disabled="
            Object.keys(errors).length > 0 ||
              !name_organization.length ||
              !inn.length ||
              !phone.length
          "
        >
          Сохранить
        </vButton>
      </fieldset>
    </Form>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import convertPhoneToFull from '@/utils/convertPhoneToFull'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
export default {
  name: 'VSectionReq',
  components: {
    Form,
    vInput,
    vButton,
  },

  data() {
    return {
      name_organization: '',
      inn: '',
      phone: '',
    }
  },

  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('requisites', ['REQUISITES']),
  },

  async mounted() {
    this.TOGGLE_PRELOADER(true)
    await this.FETCH_REQUISITES(this.GET_AUTHENTIFICATED)
    await this.fetchData()
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },

  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('requisites', ['FETCH_REQUISITES', 'SAVE_REQUISITES']),
    fetchData() {
      this.name_organization = this.REQUISITES.name_organization
        ? this.REQUISITES.name_organization
        : ''
      this.inn = this.REQUISITES.inn ? this.REQUISITES.inn : ''
      this.phone = this.filterPhone(this.REQUISITES.phone)
    },
    filterPhone(phone) {
      if (phone) {
        let ph = phone.replace(/\s/g, '')
        ph = ph.slice(1)
        return convertPhoneToFull(ph)
      }
      return ''
    },
    async checkForm() {
      this.TOGGLE_PRELOADER(true)
      const req = {
        token: this.GET_AUTHENTIFICATED,
        data: {
          name_organization: this.name_organization,
          inn: this.inn,
          phone: convertPhoneFormat(this.phone),
        },
      }
      await this.SAVE_REQUISITES(req)
      this.TOGGLE_PRELOADER(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.sectionProfileReq {
  height: calc(100vh - 70px);
}
</style>
